import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactUs = () => (
  <Layout>
    <SEO title="Thank You" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-8-widescreen">
            <h1 className="title is-size-1">Thanks for Contacting Us</h1>
            <p>Your message has been sent.</p>
            <p>Someone from our team will respond within 48 hours.</p>
            <p>
              If you'd prefer to speak on the phone call us at 231-933-5207.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactUs
